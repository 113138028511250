<script lang="ts">
	import ButtonLoader from '$lib/loaders/buttonLoader.svelte';
	import { createEventDispatcher } from 'svelte';
	import AtomIcon from '../atoms/AtomIcon.svelte';
	import type { ButtonVariant } from '../models';
	import AtomTooltip from './AtomTooltip.svelte';

	const dispatch = createEventDispatcher();

	export let variant: ButtonVariant = 'default';
	export let size: 'very_small' | 'small' | 'regular' | 'large' = 'regular';
	export let type: 'reset' | 'button' | 'submit' = 'button';
	export let iconPosition: 'left' | 'right' = 'right';
	export let round = false;
	export let fullWidth: boolean | 'sm' = false;
	export let loading = false;
	export let handleLoadingInternally = false;
	export let text: string | undefined = undefined;
	export let disabled = false;
	export let iconClass: string = '';
	export let focusVisible = true;
	export let iconMaxHeightPx: number | undefined = undefined;
	export let tooltip = '';
	export let tooltipDivClass: string = '';
	export let stopPropagation = false;
	export let id: string | undefined = undefined;
	export let reverse = false;
	export let center = true;
	export let icon: any = undefined; // There ar two ways to handle the icon, one is to pass the icon as a slot, the other is to pass the icon as a prop
	// onClick is used for buttons in a table for example
	export let onClick: ((e: any) => Promise<any> | void) | undefined = undefined;

	$: hasAnIcon = $$slots.icon || icon;

	let isIconOnly = !text && hasAnIcon;
	let roundButtonRadius: undefined | number = undefined;
	let _iconSize: undefined | number = 32;
	export let iconSize: undefined | number = undefined; // This is an override from the parent

	let internalLoading = false;

	$: displayLoader = handleLoadingInternally ? internalLoading : loading;

	$: {
		isIconOnly = !text && hasAnIcon;
		if (size === 'very_small') roundButtonRadius = 28;
		if (size === 'small') roundButtonRadius = 32;
		if (size === 'regular') roundButtonRadius = 36;
		if (size === 'large') roundButtonRadius = 40;

		if (size === 'very_small') _iconSize = 3;
		if (size === 'small') _iconSize = 3;
		if (size === 'regular') _iconSize = 4;
		if (size === 'large') _iconSize = 5;
		// We comment the value for tailwind min-h-[32px] min-h-[36px] min-h-[40px], min-w-[32px] min-w-[36px] min-w-[40px]
	}

	$: finalIconClass = `${variant === 'default' ? 'fill-white' : ''}
		${!disabled && variant === 'secondary' ? 'fill-primary' : ''}
		${disabled && variant === 'secondary' ? 'fill-primary-400' : ''}
		${disabled && variant === 'transparent' ? 'fill-gray-400' : ''}
	 	${iconClass}`;

	// Only used for icon only button, when the button is supposed to be fullwidth, we make it so
	$: iconOnlyWidth = fullWidth ? '100%' : `${roundButtonRadius}px`;

	function startLoading() {
		if (handleLoadingInternally) {
			internalLoading = true;
			disabled = true;
		}
	}

	function stopLoading() {
		if (handleLoadingInternally) {
			internalLoading = false;
			disabled = false;
		}
	}
</script>

<AtomTooltip title={tooltip} portalDivClass={tooltipDivClass}>
	<svelte:element
		this="button"
		{type}
		{disabled}
		{id}
		data-testid={$$restProps['data-testid']}
		class:bg-primary={!disabled && variant === 'default'}
		class:bg-primary-400={disabled && variant === 'default'}
		class:text-white={variant === 'default'}
		class:hover:bg-primary-500={!disabled && variant === 'default'}
		class:bg-white={variant === 'secondary'}
		class:text-primary-theme-color={!disabled && variant === 'secondary'}
		class:text-primary-theme-color-400={disabled && variant === 'secondary'}
		class:shadow-2px-inset={variant === 'secondary'}
		class:text-gray-800={!disabled && variant === 'transparent'}
		class:text-gray-500={disabled && variant === 'transparent'}
		class:hover:bg-slate-100={!disabled &&
			(variant === 'transparent' || variant === 'secondary')}
		class:px-2={!isIconOnly && size === 'very_small'}
		class:py-1={!isIconOnly && size === 'very_small'}
		class:px-2.5={!isIconOnly && size === 'small'}
		class:py-1.5={!isIconOnly && size === 'small'}
		class:px-3={!isIconOnly && size === 'regular'}
		class:py-2={!isIconOnly && size === 'regular'}
		class:px-3.5={!isIconOnly && size === 'large'}
		class:py-2.5={!isIconOnly && size === 'large'}
		class:w-full={!isIconOnly && fullWidth}
		class:sm:w-auto={fullWidth === 'sm'}
		class:w-fit={!isIconOnly && fullWidth === false}
		class:rounded-full={round}
		class:rounded-md={!round}
		class:justify-start={!center && !reverse}
		class:justify-center={center}
		class:justify-end={!center && reverse}
		class="{isIconOnly ? `min-w-[${iconOnlyWidth}] min-h-[${roundButtonRadius}px] ` : ''} 
	{variant === 'transparent' ? `dark:hover:bg-slate-600 dark:fill-white ` : ''} 
	 inline-flex {reverse
			? 'flex-row-reverse'
			: ''} h-fit shrink-0 items-center gap-x-3 py-1.5 text-sm font-semibold {$$restProps.class} {focusVisible
			? 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
			: ''}"
		on:click={async (e) => {
			if (stopPropagation) e.stopPropagation();

			startLoading();

			try {
				dispatch('click', e);
				await onClick?.(e);
			} catch (error) {
				stopLoading();
				throw error; // Bubble up the threw error
			}
			stopLoading();
		}}
		style={(isIconOnly ? `width: ${iconOnlyWidth}; height: ${roundButtonRadius}px;` : '') +
			` ${$$restProps.style || ''} `}
	>
		<div class="flex items-center gap-2 {iconPosition === 'left' ? 'flex-row-reverse' : ''}">
			{#if text}
				<span>{text}</span>
			{/if}
			{#if hasAnIcon || loading}
				<AtomIcon 
					size={iconSize || _iconSize} 
					class={finalIconClass} 
					maxHeightPx={iconMaxHeightPx}
					icon={displayLoader ? ButtonLoader : icon}
				>
					{#if loading}
						<ButtonLoader />
					{:else}
						<slot name="icon" />
					{/if}
				</AtomIcon>
			{/if}
		</div>
	</svelte:element>
</AtomTooltip>
